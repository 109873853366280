<template>
	<div class="TriggerActionMusicEntry triggerActionForm" v-if="!spotifyConnected">
		<div class="info warn">
			<img src="@/assets/icons/info.svg" alt="info">
			<i18n-t scope="global" class="label" tag="p" keypath="triggers.actions.music.header">
				<template #LINK>
					<a @click="$store('params').openParamsPage(contentConnexions, 'spotify')">{{ $t("triggers.actions.music.header_link") }}</a>
				</template>
			</i18n-t>
		</div>
	</div>

	<div class="TriggerActionMusicEntry triggerActionForm" v-else>
		<ParamItem 							:paramData="actions_conf" v-model="action.musicAction" />
		<ParamItem v-if="showTrackInput"	:paramData="track_conf" v-model="action.track" />
		<ParamItem v-if="showTrackInput"	:paramData="confirmSongRequest_conf" v-model="action.confirmMessage" />
		<ParamItem v-if="showPlaylistInput"	:paramData="playlist_conf" v-model="action.playlist" />
	</div>
</template>

<script lang="ts">
import { MusicTriggerEvents, TriggerEventPlaceholders, TriggerEventTypeCategories, TriggerMusicTypes, TriggerTypes, type ITriggerPlaceholder, type TriggerActionMusicEntryData, type TriggerData, type TriggerMusicEventType, type TriggerMusicTypesValue } from '@/types/TriggerActionDataTypes';
import { TwitchatDataTypes } from '@/types/TwitchatDataTypes';
import SpotifyHelper from '@/utils/music/SpotifyHelper';
import { Component, Prop } from 'vue-facing-decorator';
import ParamItem from '../../../ParamItem.vue';
import AbstractTriggerActionEntry from './AbstractTriggerActionEntry.vue';


@Component({
	components:{
		ParamItem,
	},
})
export default class TriggerActionMusicEntry extends AbstractTriggerActionEntry {

	@Prop
	declare action:TriggerActionMusicEntryData;
	
	@Prop
	declare triggerData:TriggerData;

	public actions_conf:TwitchatDataTypes.ParameterData<TriggerMusicTypesValue, TriggerMusicTypesValue> = { type:"list", value:"0", listValues:[], icon:"music" };
	public track_conf:TwitchatDataTypes.ParameterData<string> = { type:"string", longText:true, value:"", icon:"music", maxLength:500 };
	public confirmSongRequest_conf:TwitchatDataTypes.ParameterData<string> = { type:"string", longText:true, value:"", icon:"whispers", maxLength:500 };
	public playlist_conf:TwitchatDataTypes.ParameterData<string> = { type:"string", value:"", icon:"info", maxLength:500 };

	public get spotifyConnected():boolean { return SpotifyHelper.instance.connected; }
	public get showTrackInput():boolean { return this.actions_conf.value == TriggerMusicTypes.ADD_TRACK_TO_QUEUE; }
	public get showPlaylistInput():boolean { return this.actions_conf.value == TriggerMusicTypes.START_PLAYLIST; }
	public get contentOverlays():TwitchatDataTypes.ParameterPagesStringType { return TwitchatDataTypes.ParameterPages.OVERLAYS; } 
	public get contentConnexions():TwitchatDataTypes.ParameterPagesStringType { return TwitchatDataTypes.ParameterPages.CONNEXIONS; } 

	public mounted():void {
		//List all available trigger types
		let events:TriggerMusicEventType[] = []
		events.push( {labelKey:"triggers.actions.music.param_actions_default", icon:"music", value:"0", category:TriggerEventTypeCategories.MUSIC} ),
		
		events = events.concat(MusicTriggerEvents());
		this.actions_conf.value					= this.action.musicAction? this.action.musicAction : events[0].value;
		this.actions_conf.listValues			= events;
		this.actions_conf.labelKey				= "triggers.actions.music.param_actions";
		this.track_conf.labelKey				= "triggers.actions.music.param_track";
		this.confirmSongRequest_conf.labelKey	= "triggers.actions.music.param_confirmSongRequest";
		this.playlist_conf.labelKey				= "triggers.actions.music.param_playlist";

	}

	/**
	 * Called when the available placeholder list is updated
	 */
	public onPlaceholderUpdate(list:ITriggerPlaceholder<any>[]):void {
		this.track_conf.placeholderList = list;
		this.confirmSongRequest_conf.placeholderList = list.concat(TriggerEventPlaceholders(TriggerTypes.TRACK_ADDED_TO_QUEUE));
		this.playlist_conf.placeholderList = list;
	}

}
</script>

<style scoped lang="less">
.TriggerActionMusicEntry{

}
</style>