<template>
	<div class="chatscoperequester chatMessage highlight alert">
		<CloseButton @click="deleteMessage()" theme="light" />

		<span class="chatMessageTime" v-if="$store('params').appearance.displayTime.value">{{time}}</span>

		<img src="@/assets/icons/alert.svg" alt="alert" class="icon">

		<div class="info">
			<p class="title">{{ $tc("chat.scope_request.title", messageData.twitch_scopes) }}</p>

			<ul class="scopes">
				<li v-for="s in messageData.twitch_scopes" :key="s">
					<Icon v-if="getScopeImage(s)" :name="getScopeImage(s)" theme="light" />
					<span>{{ $t("global.twitch_scopes."+s) }}</span>
				</li>
			</ul>

			<Button class="grantBt" small light alert icon="lock_fit" @click="requestScopes()">{{ $t('chat.scope_request.grantBt') }}</Button>
			<Button class="filterBt" small light alert icon="filters" @click="openFilters()">{{ $t('chat.scope_request.openFiltersBt') }}</Button>
		</div>
	</div>
</template>

<script lang="ts">
import type { TwitchatDataTypes } from '@/types/TwitchatDataTypes';
import { TwitchScope2Icon, type TwitchScopesString } from '@/utils/twitch/TwitchScopes';
import { Component, Prop } from 'vue-facing-decorator';
import Button from '../Button.vue';
import AbstractChatMessage from './AbstractChatMessage.vue';
import CloseButton from '../CloseButton.vue';

@Component({
	components:{
		Button,
		CloseButton,
	},
	emits:["onRead", "openFilters"]
})
export default class ChatScopeRequester extends AbstractChatMessage {
	
	@Prop
	declare messageData:TwitchatDataTypes.MessageScopeRequestData;

	public getScopeImage(s:TwitchScopesString):string {
		return TwitchScope2Icon[s] ?? "";
	}

	public mounted():void {
		
	}

	public requestScopes():void {
		this.$store("auth").newScopesToRequest = this.messageData.twitch_scopes;
	}

	public openFilters():void {
		this.$emit("openFilters");
	}

	public deleteMessage():void {
		this.$store("chat").deleteMessage(this.messageData);
	}

}
</script>

<style scoped lang="less">
.chatscoperequester{
	.info {
		width: 100%;
		.title {
			font-weight: bold;
			margin-bottom: .5em;
			width: calc(100% - 2.5em);
		}
		.scopes {
			display: flex;
			flex-direction: column;
			gap: .25em;
			list-style-position: inside;
			li {
				.icon {
					height: 1em;
					width: 1em;
					margin-right: .5em;
					vertical-align: middle;
				}
			}
		}

		.grantBt, .filterBt {
			margin: auto;
			margin-top: .5em;
			display: flex;
		}
	}
}
</style>