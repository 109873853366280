import GraphemeSplitter from "grapheme-splitter";

/**
* Created : 25/08/2022 
*/
export default class UnicodeUtils {

	private static _instance:UnicodeUtils;
	
	private gSplitter = new GraphemeSplitter();
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():UnicodeUtils {
		if(!UnicodeUtils._instance) {
			UnicodeUtils._instance = new UnicodeUtils();
		}
		return UnicodeUtils._instance;
	}
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public normalizeAlphaNum(src:string, exceptions:string = ""):string {
		let n = this.getUnicodeLetters();
		src = this.normalizeText(src, n, exceptions);
		n = this.getUnicodeNumbers();
		src = this.normalizeText(src, n, exceptions);
		n = this.getUnicodeFlags();
		src = this.normalizeText(src, n, exceptions);
		return src;
	}

	public normalizeLetters(src:string, exceptions:string = ""):string {
		let chars = this.getUnicodeLetters();
		return this.normalizeText(src, chars, exceptions)
	}

	public normalizeNumbers(src:string, exceptions:string = ""):string {
		let n = this.getUnicodeNumbers();
		return this.normalizeText(src, n, exceptions)
	}

	public normalizeMarks(src:string, exceptions:string = "") {
		let n = this.getUnicodeMarks();
		return this.normalizeText(src, n, exceptions)
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/

	private normalizeText(src:string, charTable:{[key:string]:string}, exceptions:string):string {
		for (var t = this.gSplitter.splitGraphemes(src), u = [], m = 0; m < t.length; m++) {
			const a = t[m];
			if (exceptions.indexOf(a) != -1) {
				u.push(a);
				continue
			}
			var i = !1;
			for (const key in charTable)
				if (charTable[key].indexOf(a) != -1) {
					u.push(key);
					var i = !0;
					break
				}
			i || u.push(a)
		}
		return u.join("")
	}

	public getUnicodeLetters():{[key:string]:string} {
		return {
			A: "\u24B6\uD83C\uDD50\uFF21\uD835\uDC00\uD835\uDD6C\uD835\uDC68\uD835\uDCD0\uD835\uDD38\uD835\uDE70\uD835\uDDA0\uD835\uDDD4\uD835\uDE3C\uD835\uDE08\uD83C\uDD30\uD83C\uDD70\uD83C\uDDE6\uD835\uDD04\u1D2C\u2200\xC0\xC1\xC2\xC3\xC4\xC5\u0100\u0102\u0104\u01CD\u01DE\u01E0\u01FA\u0200\u0202\u0226\u023A\u0386\u0391\u0410\u04D0\u04D2\u15C5\u15E9\u1D00\u1E00\u1EA0\u1EA2\u1EA4\u1EA6\u1EA8\u1EAA\u1EAC\u1EAE\u1EB0\u1EB2\u1EB4\u1EB6\u1F08\u1F09\u1F0A\u1F0B\u1F0C\u1F0D\u1F0E\u1F0F\u1F88\u1F89\u1F8A\u1F8B\u1F8C\u1F8D\u1F8E\u1F8F\u1FB8\u1FB9\u1FBA\u1FBB\u1FBC\u20B3\u212B\uA4EE\uD800\uDEA0\uD800\uDECE\uD800\uDF00\uD835\uDEA8\uD835\uDEE2\uD835\uDF1C\uD835\uDF56\uD835\uDF90\uD83C\uDD10\u15C4\u2C6F\uA4EF\u13AA\u13AF\u13CC\u15C6\u15C7\u15C8\u15C9\u15CA\u15CB\u15CC\u0040",
			a: "\u24D0\uFF41\uD835\uDC1A\uD835\uDD86\uD835\uDC82\uD835\uDCEA\uD835\uDD52\uD835\uDE8A\uD835\uDDBA\uD835\uDDEE\uD835\uDE56\uD835\uDE22\uD835\uDD1E\u2090\u1D43\u249C\u0250\u0252\u120D\uFF91\xE0\xE1\xE2\xE3\xE4\xE5\u0101\u0103\u0105\u01CE\u01DF\u01E1\u01FB\u0201\u0203\u0227\u0251\u03AC\u03B1\u0430\u04D1\u04D3\u1D45\u1D8F\u1E01\u1E9A\u1EA1\u1EA3\u1EA5\u1EA7\u1EA9\u1EAB\u1EAD\u1EAF\u1EB1\u1EB3\u1EB5\u1EB7\u1F00\u1F01\u1F02\u1F03\u1F04\u1F05\u1F06\u1F07\u1F70\u1F71\u1F80\u1F81\u1F82\u1F83\u1F84\u1F85\u1F86\u1F87\u1FB0\u1FB1\u1FB2\u1FB3\u1FB4\u1FB6\u1FB7\u2C65\u2C80\u2C81\uD835\uDEC2\uD835\uDEFC\uD835\uDF36\uD835\uDF70\u1D44\u1D9B\uD801\uDC1F\u237A\xAA\xAD",
			B: "\u24B7\uD83C\uDD51\uFF22\uD835\uDC01\uD835\uDD6D\uD835\uDC69\uD835\uDCD1\uD835\uDD39\uD835\uDE71\uD835\uDDA1\uD835\uDDD5\uD835\uDE3D\uD835\uDE09\uD83C\uDD31\uD83C\uDD71\uD83C\uDDE7\uD835\uDD05\u1D2E\uD801\uDC12\xDF\u0181\u0243\u026E\u0299\u0392\u03B2\u03D0\u0412\u0432\u15F7\u15F8\u15F9\u15FE\u15FF\u1656\u1657\u1658\u165D\u165E\u165F\u16D2\u16D4\u1D03\u1D2F\u1D5D\u1D66\u1E02\u1E04\u1E06\u1E9E\u20BF\u212C\u2C82\u2C83\uA4D0\uA796\uA7B4\uA7B5\uD800\uDE82\uD800\uDEA1\uD800\uDF01\uD800\uDF31\uD801\uDC42\uD801\uDC1A\uD835\uDEA9\uD835\uDEE3\uD835\uDF1D\uD835\uDF57\uD835\uDF91\uD83C\uDD11\uD835\uDEC3\uD835\uDEFD\uD835\uDF37\uD835\uDF71\u15FA\u1600\u1660\u1659\uA4ED\uA79A\uA79B\uD801\uDC3A\u0E3F\u13F0\u13F4\u13F8\u13FC\uA79C\uA79D",
			b: "\u24D1\uFF42\uD835\uDC1B\uD835\uDD87\uD835\uDC83\uD835\uDCEB\uD835\uDD53\uD835\uDE8B\uD835\uDDBB\uD835\uDDEF\uD835\uDE57\uD835\uDE23\uD835\uDD1F\u1D47\u249D\u130C\u4E43\xFE\u0180\u0184\u0185\u042C\u044C\u1D6C\u1D80\u1E03\u1E05\u1E07\uA767\uD800\uDF1C\u10A6\u10AA\u13CF\u1472\u1473\u147E\u147F\u1480\u1481\u1482\u1483\u1488\u2422\uA797",
			C: "\u24B8\uD83C\uDD52\uFF23\uD835\uDC02\uD835\uDD6E\uD835\uDC6A\uD835\uDCD2\u2102\uD835\uDE72\uD835\uDDA2\uD835\uDDD6\uD835\uDE3E\uD835\uDE0A\uD83C\uDD32\uD83C\uDD72\uD83C\uDDE8\u212D\u1466\u0186\xC7\u0106\u0108\u010A\u010C\u0187\u023B\u0297\u03DA\u03F9\u03FE\u0421\u0480\u04AA\u1E08\u20B5\u2103\u2201\u2CA4\u2D4E\uA4DA\uD83C\uDD2B\uD800\uDEA2\uD800\uDF02\uD801\uDC15\uD801\uDC3D\uD801\uDCA8\xA9\uD804\uDC1D\uD83C\uDD12\u03FD\u03FF\uA4DB\uA73E\uD801\uDC23\uD801\uDCA7\u10C2\u13DF\u13E3\u1450\u1451\u1452\u1453\u1454\u1455\u1456\u145D\u145E\u145F\u1460\u1461\u1462\u1463\u1464\u1465\u1466\u1469\u146A\u14BC\u2183\u2185\uA792",
			c: "\u24D2\uFF43\uD835\uDC1C\uD835\uDD88\uD835\uDC84\uD835\uDCEC\uD835\uDD54\uD835\uDE8C\uD835\uDDBC\uD835\uDDF0\uD835\uDE58\uD835\uDE24\uD835\uDD20\uA700\u1D9C\u249E\u0254\u122D\xE7\u0107\u0109\u010B\u010D\u0188\u023C\u0255\u037C\u03C2\u03DB\u03F2\u0441\u0481\u04AB\u1D04\u1E09\u2CA5\uA794\uD835\uDED3\uD835\uDF0D\uD835\uDF47\uD835\uDF81\u037B\u037D\u1D10\u1D53\uA73F\uD801\uDC4B\uD801\uDC6E\u1004\u1041\uA793\u2184",
			D: "\u24B9\uD83C\uDD53\uFF24\uD835\uDC03\uD835\uDD6F\uD835\uDC6B\uD835\uDCD3\uD835\uDD3B\uD835\uDE73\uD835\uDDA3\uD835\uDDD7\uD835\uDE3F\uD835\uDE0B\uD83C\uDD33\uD83C\uDD73\uD83C\uDDE9\uD835\uDD07\u1D30\u15E1\u10A7\xD0\u010E\u0110\u0189\u018A\u15DF\u15E0\u15E4\u15E5\u15E6\u15EA\u15EB\u15EC\u1D05\u1D06\u1E0E\u1E0C\u1E0A\u1E10\u1E12\u2145\uA4D3\uD800\uDF03\uD801\uDCC8\uD801\uDCC9\uD801\uDCF0\uD804\uDC25\uD835\uDEAB\uD835\uDEE5\uD835\uDF1F\uD835\uDF59\uD835\uDF93\uD83C\uDD13\u15ED\uA4F7\u10A7\u13A0",
			d: "\u24D3\uFF44\uD835\uDC1D\uD835\uDD89\uD835\uDC85\uD835\uDCED\uD835\uDD55\uD835\uDE8D\uD835\uDDBD\uD835\uDDF1\uD835\uDE59\uD835\uDE25\uD835\uDD21\u1D48\u249F\u12D5\u010F\u0111\u0221\u0256\u0257\u0500\u0501\u1D6D\u1D81\u1E0B\u1E0D\u1E0F\u1E11\u1E13\u2146\uA771\u13E7\u146F\u1470\u1471\u147A\u147B\u147C\u147D\u1484\u1487",
			E: "\u24BA\uD83C\uDD54\uFF25\uD835\uDC04\uD835\uDD70\uD835\uDC6C\uD835\uDCD4\uD835\uDD3C\uD835\uDE74\uD835\uDDA4\uD835\uDDD8\uD835\uDE40\uD835\uDE0C\uD83C\uDD34\uD83C\uDD74\uD83C\uDDEA\uD835\uDD08\u1D31\u018E\xC8\xC9\xCA\xCB\u0112\u0114\u0116\u0118\u011A\u0204\u0206\u0228\u0246\u0388\u0395\u0400\u0401\u0415\u04D6\u1D07\u1E14\u1E16\u1E18\u1E1A\u1E1C\u1EB8\u1EBA\u1EBC\u1EBE\u1EC0\u1EC2\u1EC4\u1EC6\u1F18\u1F19\u1F1A\u1F1B\u1F1C\u1F1D\u1FC8\u1FC9\u2130\u2D39\uA4F0\uD800\uDE86\uD800\uDEA4\uD800\uDF04\uD804\uDC1A\uD835\uDEAC\uD835\uDEE6\uD835\uDF20\uD835\uDF5A\uD835\uDF94\uD83C\uDD14\u1D32\u2203\u2204\u2C7B\uA4F1\u0F47\u0F5B\u13AC",
			e: "\u24D4\uFF45\uD835\uDC1E\uD835\uDD8A\uD835\uDC86\uD835\uDCEE\uD835\uDD56\uD835\uDE8E\uD835\uDDBE\uD835\uDDF2\uD835\uDE5A\uD835\uDE26\uD835\uDD22\u2091\u1D49\u24A0\u01DD\u0258\u127F\u4E47\xE8\xE9\xEA\xEB\u0113\u0115\u0117\u0119\u011B\u0205\u0207\u0229\u0247\u0435\u0450\u0451\u04BC\u04BE\u04BD\u04BF\u04D7\u1D92\u1DB1\u1E15\u1E17\u1E19\u1E1B\u1E1D\u1EB9\u1EBB\u1EBD\u1EBF\u1EC1\u1EC3\u1EC5\u1EC7\u212E\u212F\u2147\u018F\u0259\u04D8\u04D9\u04DA\u04DB\u1D4A\u2094",
			F: "\u24BB\uD83C\uDD55\uFF26\uD835\uDC05\uD835\uDD71\uD835\uDC6D\uD835\uDCD5\uD835\uDD3D\uD835\uDE75\uD835\uDDA5\uD835\uDDD9\uD835\uDE41\uD835\uDE0D\uD83C\uDD35\uD83C\uDD75\uD83C\uDDEB\uD835\uDD09\u2E01\u2132\uA7FB\u0191\u03DC\u04FA\u1E1E\u20A3\u2109\u2131\uA4DD\uA730\uA798\uD800\uDE87\uD800\uDEA5\uD800\uDF05\uD83C\uDD15\u214E\uA4DE\uA77B",
			f: "\u24D5\uFF46\uD835\uDC1F\uD835\uDD8B\uD835\uDC87\uD835\uDCEF\uD835\uDD57\uD835\uDE8F\uD835\uDDBF\uD835\uDDF3\uD835\uDE5B\uD835\uDE27\uD835\uDD23\u1DA0\u24A1\u0287\u127B\uFF77\u0192\u03DD\u04FB\u1D6E\u1D82\u1E1F\u1E9D\uA799\u1DA1\uA77C",
			G: "\u24BC\uD83C\uDD56\uFF27\uD835\uDC06\uD835\uDD72\uD835\uDC6E\uD835\uDCD6\uD835\uDD3E\uD835\uDE76\uD835\uDDA6\uD835\uDDDA\uD835\uDE42\uD835\uDE0E\uD83C\uDD36\uD83C\uDD76\uD83C\uDDEC\uD835\uDD0A\u1D33\u2141\u011C\u011E\u0120\u0122\u0193\u01E4\u01E6\u01F4\u0262\u029B\u050C\u050D\u1E20\u20B2\uA4D6\uA7A0\uD800\uDF3E\uD83C\uDD16\uA4E8\u13B6\u13C0\u13E9\u13F5\u13FD\u13F3\u13FB\u10BA",
			g: "\u24D6\uFF47\uD835\uDC20\uD835\uDD8C\uD835\uDC88\uD835\uDCF0\uD835\uDD58\uD835\uDE90\uD835\uDDC0\uD835\uDDF4\uD835\uDE5C\uD835\uDE28\uD835\uDD24\u2089\u1D4D\u24A2\u0183\u03F1\u1297\u011D\u011F\u0121\u0123\u01E5\u01E7\u01F5\u0260\u0261\u0581\u1D83\u1DA2\u1E21\u210A\uA76E\uA76F\uA7A1\uA7AC\u0253\u03EC\u03ED\u0431\uD835\uDEE0\uD835\uDF1A\uD835\uDF54\uD835\uDF8E",
			H: "\u24BD\uD83C\uDD57\uFF28\uD835\uDC07\uD835\uDD73\uD835\uDC6F\uD835\uDCD7\u210D\uD835\uDE77\uD835\uDDA7\uD835\uDDDB\uD835\uDE43\uD835\uDE0F\uD83C\uDD37\uD83C\uDD77\uD83C\uDDED\u210C\u1D34\u0124\u01F6\u021E\u029C\u0389\u0397\u041D\u043D\u04A2\u04A4\u04A3\u04A5\u04C7\u04C8\u04C9\u04CA\u050A\u050B\u0528\u0529\u16BA\u1E22\u1E24\u1E26\u1E28\u1E2A\u1D78\u1F28\u1F29\u1F2A\u1F2B\u1F2C\u1F2D\u1F2E\u1F2F\u1F98\u1F99\u1F9A\u1F9B\u1F9C\u1F9D\u1F9E\u1F9F\u1FCA\u1FCB\u1FCC\u20B6\u210B\u2C67\u2C8E\uA4E7\uA726\uA7AA\u2C8F\uD800\uDECF\uD801\uDC8E\uD83C\uDD17\uD835\uDEAE\uD835\uDEE8\uD835\uDF22\uD835\uDF5C\uD835\uDF96\u13BB\uA7F8",
			h: "\u24D7\uFF48\uD835\uDC21\uD835\uDD8D\uD835\uDC89\uD835\uDCF1\uD835\uDD59\uD835\uDE91\uD835\uDDC1\uD835\uDDF5\uD835\uDE5D\uD835\uDE29\uD835\uDD25\u2095\u02B0\u24A3\u0265\u12D8\u3093\u0125\u0127\u021F\u0266\u0267\u0452\u045B\u0570\u1E23\u1E25\u1E27\u1E29\u1E2B\u1E96\u210E\u210F\u2C68\uA727\uA795\uD800\uDF37\u053F\u056F\u1DA3\u0B73\u10AF\u13C2\u13E5\u0195",
			I: "\u24BE\uD83C\uDD58\uFF29\uD835\uDC08\uD835\uDD74\uD835\uDC70\uD835\uDCD8\uD835\uDD40\uD835\uDE78\uD835\uDDA8\uD835\uDDDC\uD835\uDE44\uD835\uDE10\uD83C\uDD38\uD83C\uDD78\uD83C\uDDEE\u2111\u1D35\xCC\xCD\xCE\xCF\u0128\u012A\u012C\u012E\u0130\u0197\u01CF\u0208\u020A\u026A\u038A\u0399\u03AA\u0406\u0407\u04CF\u1E2C\u1E2E\u1DA6\u1EC8\u1ECA\u1F38\u1F39\u1F3A\u1F3B\u1F3C\u1F3D\u1F3E\u1F3F\u1FD8\u1FD9\u1FDA\u1FDB\u2110\u2223\u2C92\u2D4A\u2D4F\uA4F2\uA781\uA7AE\uA7FE\uD800\uDE88\uD800\uDE8A\uD800\uDEA6\uD800\uDF06\uD800\uDF39\uD801\uDC83\uD804\uDC21\uD800\uDF09\uD800\uDE91\uD800\uDF20\uD835\uDEB0\uD835\uDEEA\uD835\uDF24\uD835\uDF5E\uD835\uDF98\uD83C\uDD18\u0C79\u13C6",
			i: "\u24D8\uFF49\uD835\uDC22\uD835\uDD8E\uD835\uDC8A\uD835\uDCF2\uD835\uDD5A\uD835\uDE92\uD835\uDDC2\uD835\uDDF6\uD835\uDE5E\uD835\uDE2A\uD835\uDD26\u1D62\u2071\u24A4\u1D09\u130E\uFF89\xEC\xED\xEE\xEF\u0129\u012B\u012D\u012F\u01D0\u0209\u020B\u0268\u03AF\u03CA\u0456\u0457\u1D96\u1E2D\u1E2F\u1EC9\u1ECB\u1F76\u1F77\u1F30\u1F31\u1F32\u1F33\u1F34\u1F35\u1F36\u1F37\u1FD0\u1FD1\u1FD2\u1FD3\u1FD6\u1FD7\u2139\u2148\u2C93\uD835\uDECA\uD835\uDF04\uD835\uDF3E\uD835\uDF78\uD835\uDEA4\u1D4E\u1DA5\u13A5",
			J: "\u24BF\uD83C\uDD59\uFF2A\uD835\uDC09\uD835\uDD75\uD835\uDC71\uD835\uDCD9\uD835\uDD41\uD835\uDE79\uD835\uDDA9\uD835\uDDDD\uD835\uDE45\uD835\uDE11\uD83C\uDD39\uD83C\uDD79\uD83C\uDDEF\uD835\uDD0D\u1D36\u017F\u10B1\u0134\u0248\u0135\u01F0\u0237\u025F\u037F\u0408\u0575\u1D0A\uA4D9\uD83C\uDD19\u027E\u027F\u0534\u0538\u0542\u0546\u0550\u1602\u1603\uA4E9\uA646\uA647\uD801\uDC13\uD801\uDC22\uD801\uDC3B\uD801\uDC4A\uD801\uDC97\uD804\uDC27\uD804\uDC28\u10B1\u13AB\u13D3\u13DD\u1489\u148A\u148B\u148C\u148D\u148E\u148F\u1490\u1491\u1492\u1493\u1494\u1495\u1496\u1497\u1498\u1499\u149A\u149B\u149C\u149D\u149E\u149F\u14A0\u14A1\u14A2\uA7B2",
			j: "\u24D9\uFF4A\uD835\uDC23\uD835\uDD8F\uD835\uDC8B\uD835\uDCF3\uD835\uDD5B\uD835\uDE93\uD835\uDDC3\uD835\uDDF7\uD835\uDE5F\uD835\uDE2B\uD835\uDD27\u2C7C\u02B2\u24A5\u130B\uFF8C\u0249\u029D\u03F3\u0458\u1DA8\u2149\uD835\uDEA5",
			K: "\u24C0\uD83C\uDD5A\uFF2B\uD835\uDC0A\uD835\uDD76\uD835\uDC72\uD835\uDCDA\uD835\uDD42\uD835\uDE7A\uD835\uDDAA\uD835\uDDDE\uD835\uDE46\uD835\uDE12\uD83C\uDD3A\uD83C\uDD7A\uD83C\uDDF0\uD835\uDD0E\u1D37\uFEFC\u0136\u0198\u01E8\u039A\u03CF\u040C\u041A\u049A\u049C\u049E\u04A0\u04C3\u051E\u16D5\u1D0B\u1E30\u1E32\u1E34\u20AD\u212A\u2C69\u2C94\uA4D7\uA740\uA7A2\uD800\uDE8B\uD800\uDF0A\uD801\uDCBC\uD801\uDCBD\uD801\uDCBE\uD835\uDEB1\uD835\uDEEB\uD835\uDF25\uD835\uDF5F\uD835\uDF99\uD83C\uDD1A\uA4D8\uA7B0\u13E6\uA742",
			k: "\u24DA\uFF4B\uD835\uDC24\uD835\uDD90\uD835\uDC8C\uD835\uDCF4\uD835\uDD5C\uD835\uDE94\uD835\uDDC4\uD835\uDDF8\uD835\uDE60\uD835\uDE2C\uD835\uDD28\u2096\u1D4F\u24A6\u029E\u1315\u30BA\u0137\u0138\u0199\u01E9\u03BA\u043A\u045C\u04A1\u049F\u049B\u049D\u04C4\u051F\u1D84\u1E31\u1E33\u1E35\u2C6A\u2C95\u2CE4\uA741\uA7A3\uD801\uDCE4\uD801\uDCE5\uD801\uDCE6\uD835\uDECB\uD835\uDF05\uD835\uDF3F\uD835\uDF79\uA743\uA744\uA745",
			L: "\u24C1\uD83C\uDD5B\uFF2C\uD835\uDC0B\uD835\uDD77\uD835\uDC73\uD835\uDCDB\uD835\uDD43\uD835\uDE7B\uD835\uDDAB\uD835\uDDDF\uD835\uDE47\uD835\uDE13\uD83C\uDD3B\uD83C\uDD7B\uD83C\uDDF1\uD835\uDD0F\u1D38\u2142\u2143\u0139\u013B\u013D\u013F\u0141\u023D\u029F\u053C\u056C\u0582\u1D0C\u1E36\u1E38\u1E3A\u1E3C\u1DAB\u2112\u2CD0\u2CD1\uA4E1\uD800\uDF0B\uD801\uDC1B\uD801\uDC43\uD804\uDC09\uD83C\uDD1B\u02E5\u0393\u0403\u0413\u0433\u1D26\uA4F6\uA780\uD801\uDC39\u13B1\u13DE\u14A3\u14A4\u14A5\u14A6\u14A7\u14A8\u14A9\u14AA\u14AB\u14AC\u14AD\u14AE\u14AF\u14B0\u14B1\u14B2\u14B3\u14B4\u14B5\u14B6\u14B7\u14B8\u14B9\u14BA\u14BB\u14BD\uA748\u216C\uA7AD\uA746",
			l: "\u24DB\uFF4C\uD835\uDC25\uD835\uDD91\uD835\uDC8D\uD835\uDCF5\uD835\uDD5D\uD835\uDE95\uD835\uDDC5\uD835\uDDF9\uD835\uDE61\uD835\uDE2D\uD835\uDD29\u2097\u02E1\u24A7\u1228\uFF9A\u013A\u013C\u013E\u0142\u0196\u019A\u0140\u0131\u026B\u026C\u026D\u03B9\u1D85\u1DA9\u1E37\u1E39\u1E3B\u1E3D\uA772\u217C\uA749\uA747",
			M: "\u24C2\uD83C\uDD5C\uFF2D\uD835\uDC0C\uD835\uDD78\uD835\uDC74\uD835\uDCDC\uD835\uDD44\uD835\uDE7C\uD835\uDDAC\uD835\uDDE0\uD835\uDE48\uD835\uDE14\uD83C\uDD3C\uD83C\uDD7C\uD83C\uDDF2\uD835\uDD10\u1D39\u028D\u039C\u03FA\u03FB\u041C\u043C\u04CD\u04CE\u15F0\u164F\u16D6\u1C6C\u1C9D\u1D0D\u1E3E\u1E40\u1E42\u2133\u2C6E\u2C98\u2C99\uA4DF\uD800\uDE8E\uD800\uDEB0\uD800\uDF11\uD800\uDF3C\uD801\uDC84\uD800\uDEBF\uD835\uDEB3\uD835\uDEED\uD835\uDF27\uD835\uDF61\uD83C\uDD1C\uA7FD\u13B7",
			m: "\u24DC\uFF4D\uD835\uDC26\uD835\uDD92\uD835\uDC8E\uD835\uDCF6\uD835\uDD5E\uD835\uDE96\uD835\uDDC6\uD835\uDDFA\uD835\uDE62\uD835\uDE2E\uD835\uDD2A\u2098\u1D50\u24A8\u026F\u1320\uFFB6\u0271\u1D6F\u1D86\u1E3F\u1E41\u1E43\uA773\u019C\u0270\u057A\u1D5A\u0561\uA7FA\u0C7B",
			N: "\u24C3\uD83C\uDD5D\uFF2E\uD835\uDC0D\uD835\uDD79\uD835\uDC75\uD835\uDCDD\u2115\uD835\uDE7D\uD835\uDDAD\uD835\uDDE1\uD835\uDE49\uD835\uDE15\uD83C\uDD3D\uD83C\uDD7D\uD83C\uDDF3\uD835\uDD11\u1D3A\u0418\xD1\u0143\u0145\u0147\u014A\u019D\u01F8\u0274\u039D\u1DB0\u1E44\u1E46\u1E48\u1E4A\u2C9A\u2C9B\uA4E0\uA790\uA7A4\uD800\uDE8F\uD800\uDEAA\uD800\uDF3D\uD835\uDEB4\uD835\uDEEE\uD835\uDF28\uD835\uDF62\uD83C\uDD1D\u0376\u040D\u0419\u048A\u1D3B",
			n: "\u24DD\uFF4E\uD835\uDC27\uD835\uDD93\uD835\uDC8F\uD835\uDCF7\uD835\uDD5F\uD835\uDE97\uD835\uDDC7\uD835\uDDFB\uD835\uDE63\uD835\uDE2F\uD835\uDD2B\u2099\u207F\u24A9\u12AD\u5200\xF1\u0144\u0146\u0148\u0149\u014B\u019E\u01F9\u0272\u0273\u03AE\u03B7\u0572\u0578\u057C\u1D51\u1D70\u1D87\u1E45\u1E47\u1E49\u1E4B\u1F90\u1F91\u1F92\u1F93\u1F94\u1F95\u1F96\u1F97\u1FC2\u1FC3\u1FC4\u1FC6\u1FC7\uA774\uA791\uA7A5\uD835\uDEC8\uD835\uDF02\uD835\uDF3C\uD835\uDF76\u0377\u0438\u0439\u045D\u048B\u057D\u1D0E",
			O: "\u24C4\uD83C\uDD5E\uFF2F\uD835\uDC0E\uD835\uDD7A\uD835\uDC76\uD835\uDCDE\uD835\uDD46\uD835\uDE7E\uD835\uDDAE\uD835\uDDE2\uD835\uDE4A\uD835\uDE16\uD83C\uDD3E\uD83C\uDD7E\uD83C\uDDF4\uD835\uDD12\u1D3C\xD2\xD3\xD4\xD5\xD6\xD8\u014C\u014E\u0150\u019F\u01A0\u01D1\u01FE\u020C\u020E\u022A\u022C\u022E\u0230\u0298\u038C\u0398\u039F\u03B8\u03F4\u041E\u0472\u04E6\u04E8\u04EA\u0555\u1C50\u1C5B\u1CBF\u1E4C\u1E4E\u1E50\u1E52\u1ECC\u1ECE\u1ED0\u1ED2\u1ED4\u1ED6\u1ED8\u1F48\u1F49\u1F4A\u1F4B\u1F4C\u1F4D\u1EDA\u1EDC\u1EDE\u1EE0\u1EE2\u1FF8\u1FF9\u2C90\u2C9E\u2D54\uA4F3\uA74A\uA74C\uD800\uDE92\uD800\uDEA8\uD800\uDEAB\uD800\uDEB8\uD800\uDF08\uD800\uDF0F\uD800\uDF48\uD801\uDC04\uD801\uDC2C\uD801\uDC74\uD801\uDC86\uD801\uDCA0\uD801\uDCC2\uD801\uDCC3\uD801\uDCEA\uD801\uDCEB\uD804\uDC1E\uD804\uDC23\uD835\uDEC9\uD835\uDF03\uD835\uDF3D\uD835\uDF77\uD835\uDEAF\uD835\uDEB6\uD835\uDEE9\uD835\uDEF0\uD835\uDF23\uD835\uDF2A\uD835\uDF5D\uD835\uDF64\uD835\uDF97\uD83C\uDD1E\uD835\uDEB9\uD835\uDEF3\uD835\uDF2D\uD835\uDF67\u0A66\u0B20\u0BE6\u0C66\u0D20\u13A4\u13A7\u13BE\u13C5\u13EB\uA668\uA66A",
			o: "\u24DE\uFF4F\uD835\uDC28\uD835\uDD94\uD835\uDC90\uD835\uDCF8\uD835\uDD60\uD835\uDE98\uD835\uDDC8\uD835\uDDFC\uD835\uDE64\uD835\uDE30\uD835\uDD2C\u2092\u1D52\u24AA\u12D0\xF0\xF2\xF3\xF4\xF5\xF6\u014D\u014F\u0151\u01A1\u01D2\u01EB\u01ED\u01FF\u020D\u020F\u022B\u022D\u022F\u0231\u03BF\u03C3\u03CC\u043E\u0473\u04E7\u04E9\u04EB\u0585\u1D0F\u1D11\u1E4D\u1E4F\u1E51\u1E53\u1ECD\u1ECF\u1ED1\u1ED3\u1ED5\u1ED7\u1ED9\u1EDB\u1EDD\u1EDF\u1EE1\u1EE3\u1F40\u1F41\u1F42\u1F43\u1F44\u1F45\u1F78\u1F79\u2070\u2134\u2C91\u2C9F\u2D30\uA74B\uA74D\uD835\uDED0\uD835\uDF0A\uD835\uDF44\uD835\uDF7E\u0AE6\u0D66\u0E50\u0ED0\u0F20\u0FC0\u101D\u1040\uA669\uA66B\xB0\xBA",
			P: "\u24C5\uD83C\uDD5F\uFF30\uD835\uDC0F\uD835\uDD7B\uD835\uDC77\uD835\uDCDF\u2119\uD835\uDE7F\uD835\uDDAF\uD835\uDDE3\uD835\uDE4B\uD835\uDE17\uD83C\uDD3F\uD83C\uDD7F\uD83C\uDDF5\uD835\uDD13\u1D3E\uA7FC\u01A4\u01F7\u03A1\u0420\u048E\u1D18\u1E54\u1E56\u1D29\u1FEC\u20B1\u2117\u2C63\u2CA2\u2CC0\uA4D1\uA750\uA752\uA764\uD800\uDE95\uD800\uDF13\uD801\uDC19\uD800\uDF1B\uD835\uDEB8\uD835\uDEF2\uD835\uDF2C\uD835\uDF66\uD83C\uDD1F\uA4D2\uD804\uDC18\u13E2\uA754\uA766",
			p: "\u24DF\uFF50\uD835\uDC29\uD835\uDD95\uD835\uDC91\uD835\uDCF9\uD835\uDD61\uD835\uDE99\uD835\uDDC9\uD835\uDDFD\uD835\uDE65\uD835\uDE31\uD835\uDD2D\u209A\u1D56\u24AB\u12E8\uFF71\u01A5\u01BF\u03C1\u0440\u048F\u1D68\u1D71\u1D7D\u1D88\u1E55\u1E57\u1FE4\u1FE5\u2CA3\uA751\uA753\uA765\uD801\uDCEC\uD835\uDED2\uD835\uDF0C\uD835\uDF46\uD835\uDF80\u146B\u146C\u146D\u146E\u1476\u1477\u1478\u1479\u1486\u1474\u1475\u1485\u2374\uA755\uA76C\uA76D",
			Q: "\u24C6\uD83C\uDD60\uFF31\uD835\uDC10\uD835\uDD7C\uD835\uDC78\uD835\uDCE0\u211A\uD835\uDE80\uD835\uDDB0\uD835\uDDE4\uD835\uDE4C\uD835\uDE18\uD83C\uDD40\uD83C\uDD80\uD83C\uDDF6\uD835\uDD14\uA779\u03D8\u01EA\u01EC\u03D9\u051A\uA756\uD800\uDEAD\uD800\uDF12\uD83C\uDD20\u10B3\u10AD\uA758",
			q: "\u24E0\uFF51\uD835\uDC2A\uD835\uDD96\uD835\uDC92\uD835\uDCFA\uD835\uDD62\uD835\uDE9A\uD835\uDDCA\uD835\uDDFE\uD835\uDE66\uD835\uDE32\uD835\uDD2E\u06F9\u24AC\u12D2\u024A\u024B\u051B\u0566\uA757\uA759",
			R: "\u24C7\uD83C\uDD61\uFF32\uD835\uDC11\uD835\uDD7D\uD835\uDC79\uD835\uDCE1\u211D\uD835\uDE81\uD835\uDDB1\uD835\uDDE5\uD835\uDE4D\uD835\uDE19\uD83C\uDD41\uD83C\uDD81\uD83C\uDDF7\u211C\u1D3F\u1588\u042F\u0154\u0156\u0158\u01A6\u0210\u0212\u024C\u0280\u1E5A\u1E5C\u1E5E\u1E58\u211B\u211F\u2C64\uA4E3\uA7A6\uA776\uD83C\uDD2C\uD800\uDEAF\uD800\uDF3A\uD800\uDF42\uD83C\uDD21\u0281\u02B6\u044F\u1D19\u1D1A\uA4E4\uA79E\uA79F\u13A1\u13D2\u1586\u1587\u1588\u1589\xAE",
			r: "\u24E1\uFF52\uD835\uDC2B\uD835\uDD97\uD835\uDC93\uD835\uDCFB\uD835\uDD63\uD835\uDE9B\uD835\uDDCB\uD835\uDDFF\uD835\uDE67\uD835\uDE33\uD835\uDD2F\u1D63\u02B3\u24AD\u0279\u12EA\u5C3A\u0155\u0157\u0159\u0211\u0213\u024D\u027C\u027D\u0580\u1D72\u1D89\u1E59\u1E5B\u1E5D\u1E5F\u027A\u02B4\u13D7\uA7A7",
			S: "\u24C8\uD83C\uDD62\uFF33\uD835\uDC12\uD835\uDD7E\uD835\uDC7A\uD835\uDCE2\uD835\uDD4A\uD835\uDE82\uD835\uDDB2\uD835\uDDE6\uD835\uDE4E\uD835\uDE1A\uD83C\uDD42\uD83C\uDD82\uD83C\uDDF8\uD835\uDD16\u1506\u01A7\u015A\u015C\u015E\u0160\u0218\u0405\u054F\u1CBD\u1E60\u1E62\u1E64\u1E66\u1E68\u2C7E\uA4E2\uA731\uA7A8\uA839\uD800\uDE96\uD800\uDF14\uD801\uDC55\uD801\uDCA1\uD800\uDF43\uD801\uDC20\uD801\uDC96\uD83C\uDD22\uD83C\uDD2A\u2D27\u03E8\u1CB7\u20B4\uA644\uD801\uDC92\uD804\uDC0D\u0CBD\u0D1F\u10BD\u10C7\u13A6\u13D5\u13DA\uA682",
			s: "\u24E2\uFF53\uD835\uDC2C\uD835\uDD98\uD835\uDC94\uD835\uDCFC\uD835\uDD64\uD835\uDE9C\uD835\uDDCC\uD835\uDE00\uD835\uDE68\uD835\uDE34\uD835\uDD30\u209B\u02E2\u24AE\u01A8\u1290\u4E02\u015B\u015D\u015F\u0161\u0219\u023F\u0282\u0455\u1D8A\u1DB3\u1E61\u1E63\u1E65\u1E67\u1E69\uA7A9\uD801\uDC48\u03E9\uA645",
			T: "\u24C9\uD83C\uDD63\uFF34\uD835\uDC13\uD835\uDD7F\uD835\uDC7B\uD835\uDCE3\uD835\uDD4B\uD835\uDE83\uD835\uDDB3\uD835\uDDE7\uD835\uDE4F\uD835\uDE1B\uD83C\uDD43\uD83C\uDD83\uD83C\uDDF9\uD835\uDD17\u1D40\u22A5\u0162\u0164\u0166\u01AC\u01AE\u021A\u023E\u0372\u0373\u03A4\u0422\u0442\u04AC\u04AD\u1D1B\u1E6C\u1E6E\u1E70\u1E6A\u20AE\u20B8\u2CA6\u2CA7\uA4D4\uD800\uDE97\uD800\uDEB1\uD800\uDF15\uD800\uDF44\uD835\uDEBB\uD835\uDEF5\uD835\uDF2F\uD835\uDF69\uD83C\uDD23\uA4D5\uA7B1\uD804\uDC26\u13A2\uA690\uA691",
			t: "\u24E3\uFF54\uD835\uDC2D\uD835\uDD99\uD835\uDC95\uD835\uDCFD\uD835\uDD65\uD835\uDE9D\uD835\uDDCD\uD835\uDE01\uD835\uDE69\uD835\uDE35\uD835\uDD31\u209C\u1D57\u24AF\u1355\uFF72\u0163\u0165\u0167\u01AD\u021B\u0288\u0535\u0565\u1D75\u1DB5\u1E6B\u1E6D\u1E6F\u1E71\u1E97\u2C66\u13BF",
			U: "\u24CA\uD83C\uDD64\uFF35\uD835\uDC14\uD835\uDD80\uD835\uDC7C\uD835\uDCE4\uD835\uDD4C\uD835\uDE84\uD835\uDDB4\uD835\uDDE8\uD835\uDE50\uD835\uDE1C\uD83C\uDD44\uD83C\uDD84\uD83C\uDDFA\uD835\uDD18\u1D41\u0548\xD9\xDA\xDB\xDC\u0168\u016A\u016C\u016E\u0170\u0172\u01AF\u01D3\u01D5\u01D7\u01D9\u01DB\u0214\u0216\u0244\u0531\u0544\u054D\u1D1C\u1E72\u1E74\u1E76\u1E78\u1E7A\u1EE6\u1EE8\u1EEA\u1EEC\u1EEE\u1EF0\u1EE4\uA4F4\uD800\uDF35\uD801\uDCA9\uD801\uDCCE\uD801\uDCF6\uD83C\uDD24\u054C\uA4F5\u10AE\u10B6\u13C1\u1457\u1458\u1459\u145A\u145B\u145C\u1467\u1468",
			u: "\u24E4\uFF55\uD835\uDC2E\uD835\uDD9A\uD835\uDC96\uD835\uDCFE\uD835\uDD66\uD835\uDE9E\uD835\uDDCE\uD835\uDE02\uD835\uDE6A\uD835\uDE36\uD835\uDD32\u1D64\u1D58\u24B0\u1201\xF9\xFA\xFB\xFC\u0169\u016B\u016D\u016F\u0171\u0173\u01B0\u01D4\u01D6\u01D8\u01DA\u01DC\u0215\u0217\u0587\u1DB8\u1E73\u1E75\u1E77\u1E79\u1E7B\u1EE5\u1EE7\u1EE9\u1EEB\u1EED\u1EEF\u1EF1",
			V: "\u24CB\uD83C\uDD65\uFF36\uD835\uDC15\uD835\uDD81\uD835\uDC7D\uD835\uDCE5\uD835\uDD4D\uD835\uDE85\uD835\uDDB5\uD835\uDDE9\uD835\uDE51\uD835\uDE1D\uD83C\uDD45\uD83C\uDD85\uD83C\uDDFB\uD835\uDD19\u039B\u0474\u0476\u1E7C\u1E7E\uA4E6\uD83C\uDD25\u0245\uA4E5\u13D9\u13E4",
			v: "\u24E5\uFF56\uD835\uDC2F\uD835\uDD9B\uD835\uDC97\uD835\uDCFF\uD835\uDD67\uD835\uDE9F\uD835\uDDCF\uD835\uDE03\uD835\uDE6B\uD835\uDE37\uD835\uDD33\u1D65\u1D5B\u24B1\u028C\u1200\u221A\u03B0\u03BD\u03C5\u03CB\u03CD\u03D1\u0475\u0477\u1E7D\u1E7F\u1F50\u1F51\u1F52\u1F53\u1F54\u1F55\u1F56\u1F57\u1F7A\u1F7B\u1FE0\u1FE1\u1FE2\u1FE3\u1FE6\u1FE7\u2C71\u2C7D\uD835\uDED6\uD835\uDF10\uD835\uDF4A\uD835\uDF84\uD835\uDEDD\uD835\uDF17\uD835\uDF51\uD835\uDF8B\uD835\uDECE\uD835\uDF08\uD835\uDF42\uD835\uDF7C\u1D20\u1D27",
			W: "\u24CC\uD83C\uDD66\uFF37\uD835\uDC16\uD835\uDD82\uD835\uDC7E\uD835\uDCE6\uD835\uDD4E\uD835\uDE86\uD835\uDDB6\uD835\uDDEA\uD835\uDE52\uD835\uDE1E\uD83C\uDD46\uD83C\uDD86\uD83C\uDDFC\uD835\uDD1A\u1D42\u0174\u051C\u15EF\u1D21\u1E80\u1E82\u1E84\u1E86\u1E88\u2C72\uA4EA\uA7B6\uD801\uDC0E\uD83C\uDD26\u13B3\u13D4\uA760",
			w: "\u24E6\uFF57\uD835\uDC30\uD835\uDD9C\uD835\uDC98\uD835\uDD00\uD835\uDD68\uD835\uDEA0\uD835\uDDD0\uD835\uDE04\uD835\uDE6C\uD835\uDE38\uD835\uDD34\u02B7\u24B2\u1220\u0175\u03C9\u03CE\u0461\u047F\u051D\u1E81\u1E83\u1E85\u1E87\u1E89\u1E98\u1F60\u1F61\u1F62\u1F63\u1F64\u1F65\u1F66\u1F67\u1F7C\u1F7D\u1FA0\u1FA1\u1FA2\u1FA3\u1FA4\u1FA5\u1FA6\u1FA7\u1FF2\u1FF3\u1FF4\u1FF6\u1FF7\u2C73\uA7B7\uD835\uDEDA\uD835\uDF14\uD835\uDF4E\uD835\uDF88\uD835\uDEE1\uD835\uDF1B\uD835\uDF55\uD835\uDF8F\u13C7\u2379\uA761",
			X: "\u24CD\uD83C\uDD67\uFF38\uD835\uDC17\uD835\uDD83\uD835\uDC7F\uD835\uDCE7\uD835\uDD4F\uD835\uDE87\uD835\uDDB7\uD835\uDDEB\uD835\uDE53\uD835\uDE1F\uD83C\uDD47\uD83C\uDD87\uD83C\uDDFD\uD835\uDD1B\u157D\u03A7\u03C7\u0425\u04B2\u04FC\u166D\u16B7\u1E8A\u1E8C\u2CAC\u2D5D\uA4EB\uA7B3\uD800\uDE90\uD800\uDEB4\uD800\uDF17\uD800\uDF47\uD804\uDC0B\uD804\uDC0C\uD800\uDF22\uD83C\uDD27\uD835\uDEBE\uD835\uDEF8\uD835\uDF32\uD835\uDF6C",
			x: "\u24E7\uFF58\uD835\uDC31\uD835\uDD9D\uD835\uDC99\uD835\uDD01\uD835\uDD69\uD835\uDEA1\uD835\uDDD1\uD835\uDE05\uD835\uDE6D\uD835\uDE39\uD835\uDD35\u2093\u02E3\u24B3\u1238\uFF92\xD7\u0445\u04B3\u04FD\u166E\u1D61\u1D6A\u1D8D\u1E8B\u1E8D\u2CAD\uD835\uDEDE\uD835\uDF18\uD835\uDF52\uD835\uDF8C\uD835\uDED8\uD835\uDF12\uD835\uDF4C\uD835\uDF86",
			Y: "\u24CE\uD83C\uDD68\uFF39\uD835\uDC18\uD835\uDD84\uD835\uDC80\uD835\uDCE8\uD835\uDD50\uD835\uDE88\uD835\uDDB8\uD835\uDDEC\uD835\uDE54\uD835\uDE20\uD83C\uDD48\uD83C\uDD88\uD83C\uDDFE\uD835\uDD1C\u2144\xDD\u0176\u0178\u01B3\u0232\u024E\u028F\u038E\u03A5\u03AB\u03D2\u03D3\u03D4\u0423\u04AE\u04B0\u04EE\u04F0\u04F2\u1E8E\u1EF2\u1EF4\u1EF6\u1EF8\u1EFE\u1F59\u1F5B\u1F5D\u1F5F\u1FE8\u1FE9\u1FEA\u1FEB\u213D\u2CA8\uA4EC\uA768\uA769\uD800\uDEB2\uD800\uDF16\uD800\uDF45\uD83C\uDD28\uD835\uDEBC\uD835\uDEF6\uD835\uDF30\uD835\uDF6A\uD804\uDC22\uD801\uDC85\u13A9\u13BD\u13F2\u13FA",
			y: "\u24E8\uFF59\uD835\uDC32\uD835\uDD9E\uD835\uDC9A\uD835\uDD02\uD835\uDD6A\uD835\uDEA2\uD835\uDDD2\uD835\uDE06\uD835\uDE6E\uD835\uDE3A\uD835\uDD36\u1D67\u02B8\u24B4\u028E\u03B3\u1203\uFF98\xFD\xFF\u0177\u01B4\u0233\u024F\u040E\u0443\u045E\u04AF\u04B1\u04EF\u04F1\u04F3\u1D5E\u1D8C\u1E8F\u1E99\u1EF3\u1EF5\u1EF7\u1EF9\u1EFF\u2CA9\uD801\uDC8B\uD835\uDEC4\uD835\uDEFE\uD835\uDF38\uD835\uDF72\u03BB\u10E7\u10E9\uA694\uA695",
			Z: "\u24CF\uD83C\uDD69\uFF3A\uD835\uDC19\uD835\uDD85\uD835\uDC81\uD835\uDCE9\u2124\uD835\uDE89\uD835\uDDB9\uD835\uDDED\uD835\uDE55\uD835\uDE21\uD83C\uDD49\uD83C\uDD89\uD83C\uDDFF\u2128\u0179\u017B\u017D\u01B5\u0224\u0396\u1E90\u1E92\u1E94\u2C6B\u2C7F\u2C8C\uA4DC\uA640\uA642\uD800\uDF36\uD83C\uDD29\uD835\uDEAD\uD835\uDEE7\uD835\uDF21\uD835\uDF5B\uD835\uDF95\u13C3",
			z: "\uFF5A\uD835\uDC33\uD835\uDD9F\uD835\uDC9B\uD835\uDD03\uD835\uDD6B\uD835\uDEA3\uD835\uDDD3\uD835\uDE07\uD835\uDE6F\uD835\uDE3B\uD835\uDD37\u2082\u1DBB\u24B5\u130A\u4E59\u017A\u017C\u017E\u01B6\u0225\u0240\u0290\u0291\u1646\u1D22\u1D76\u1D8E\u1E91\u1E93\u1E95\u2C6C\u2C8D\u2D2D\uA641\uA643\u24E9",
			"a/c": "\u2100",
			"a/S": "\u2101",
			"A/s": "\u214D",
			"c/u": "\u2106",
			"c/o": "\u2105",
			"V/m": "\u33DE",
			"A/m": "\u33DF",
			SM: "\u2120",
			TM: "\u2122",
			TEL: "\u2121",
			FAX: "\u213B",
			AE: "\xC6\u01E2\u01FC\u04D4\u1D01\u1D2D",
			ae: "\xE6\u01E3\u01FD\u04D5\u1D02\u1D46\uAB31",
			IJ: "\u0132",
			ij: "\u0133",
			CE: "\u0152\u0276",
			LJ: "\u01C7",
			Lj: "\u01C8",
			lj: "\u01C9",
			DZ: "\u01C4\u01F1",
			Dz: "\u01C5\u01F2",
			dz: "\u01C6\u01F3\u02A3\u02A4\u02A5",
			oe: "\u0153\uA7F9",
			NJ: "\u01CA",
			Nj: "\u01CB",
			nj: "\u01CC",
			CD: "\uD83C\uDD2D",
			cd: "\u33C5",
			Wz: "\uD83C\uDD2E",
			HV: "\uD83C\uDD4A",
			MV: "\uD83C\uDD4B\u33B9",
			mV: "\u33B7\u33B6",
			SD: "\uD83C\uDD4C",
			SS: "\uD83C\uDD4D\u240E",
			PPV: "\uD83C\uDD4E",
			WC: "\uD83C\uDD4F\uD83C\uDD8F",
			IC: "\uD83C\uDD8B",
			PA: "\uD83C\uDD8C",
			Pa: "\u33A9",
			pA: "\u3380",
			SA: "\uD83C\uDD8D",
			AB: "\uD83C\uDD8E",
			DJ: "\uD83C\uDD90",
			CL: "\uD83C\uDD91\u2104",
			COOL: "\uD83C\uDD92",
			FREE: "\uD83C\uDD93",
			ID: "\uD83C\uDD94",
			NEW: "\uD83C\uDD95",
			NG: "\uD83C\uDD96",
			OK: "\uD83C\uDD97",
			SOS: "\uD83C\uDD98",
			"UP!": "\uD83C\uDD99",
			VS: "\uD83C\uDD9A",
			ts: "\u02A6",
			fn: "\u02A9",
			ls: "\u02AA",
			lz: "\u02AB",
			ww: "\u02AC",
			gal: "\u33FF",
			OO: "\uA74E\uA698",
			oo: "\uA74F\uA699\uA74F",
			Oy: "\u0478",
			oy: "\u0479",
			JX: "\u0514",
			AA: "\uA732",
			aa: "\uA733",
			AO: "\uA734",
			ao: "\uA735",
			AU: "\uA736\u3373",
			NUL: "\u2400",
			SOH: "\u2401",
			STX: "\u2402",
			ETX: "\u2403",
			EOT: "\u2404",
			ENQ: "\u2405",
			ACK: "\u2406",
			BEL: "\u2407",
			BS: "\u2408",
			HT: "\u2409",
			LF: "\u240A",
			VT: "\u240B",
			FF: "\u240C",
			CR: "\u240D",
			SI: "\u240F",
			DLE: "\u2410",
			DC1: "\u2411",
			DC2: "\u2412",
			DC3: "\u2413",
			DC4: "\u2414",
			NAK: "\u2415",
			SYN: "\u2416",
			ETB: "\u2417",
			CAN: "\u2418",
			EM: "\u2419",
			SUB: "\u241A",
			ESC: "\u241B",
			FS: "\u241C",
			GS: "\u241D",
			RS: "\u241E",
			US: "\u241F",
			SP: "\u2420",
			DEL: "\u2421",
			NL: "\u2424",
			hPa: "\u3371",
			da: "\u3372",
			au: "\uA737",
			bar: "\u3374",
			oV: "\u3375",
			pc: "\u3376",
			dm: "\u3377",
			"dm^2": "\u3378",
			"dm^3": "\u3379",
			IU: "\u337A",
			nA: "\u3381",
			mA: "\u3383\u3382",
			kA: "\u3384",
			KB: "\u3385",
			MB: "\u3386\uD83D\uDF6B",
			mb: "\u33D4",
			GB: "\u3387",
			cal: "\u3388",
			kcal: "\u3389",
			pF: "\u338A",
			nF: "\u338B",
			mF: "\u338C",
			mg: "\u338E\u338D",
			kg: "\u338F",
			Hz: "\u3390",
			kHz: "\u3391",
			MHz: "\u3392",
			GHz: "\u3393",
			THz: "\u3394",
			ml: "\u3396\u3395",
			dl: "\u3397",
			kl: "\u3398",
			fm: "\u3399",
			nm: "\u339A",
			mm: "\u339C\u339B",
			cm: "\u339D",
			km: "\u339E",
			KM: "\u33CE",
			"mm^2": "\u339F",
			"cm^2": "\u33A0",
			"m^2": "\u33A1",
			"km^2": "\u33A2",
			"mm^3": "\u33A3",
			"cm^3": "\u33A4",
			"m^3": "\u33A5",
			"km^3": "\u33A6",
			"m/s": "\u33A7",
			"m/s^2": "\u33A8",
			kPa: "\u33AA",
			MPa: "\u33AB",
			GPa: "\u33AC",
			rad: "\u33AD",
			"rad/s": "\u33AE",
			"rad/s^2": "\u33AF",
			ps: "\u33B0",
			ns: "\u33B1",
			ms: "\u33B3\u33B2",
			pV: "\u33B4",
			nV: "\u33B5",
			kV: "\u33B8",
			pW: "\u33BA",
			nW: "\u33BB",
			mW: "\u33BD\u33BC",
			MW: "\u33BF",
			kW: "\u33BE",
			"a.m.": "\u33C2",
			Bq: "\u33C3",
			cc: "\u33C4",
			"C/kg": "\u33C6",
			"Co.": "\u33C7",
			dB: "\u33C8",
			Gy: "\u33C9",
			ha: "\u33CA",
			in: "\u33CC",
			"K.K.": "\u33CD",
			kt: "\u33CF",
			lm: "\u33D0",
			ln: "\u33D1",
			log: "\u33D2",
			lx: "\u33D3",
			mil: "\u33D5",
			mol: "\u33D6",
			pH: "\u33D7",
			"p.m.": "\u33D8",
			PPM: "\u33D9",
			PR: "\u33DA",
			sr: "\u33DB",
			Sv: "\u33DC",
			Wb: "\u33DD",
			fl: "\uFB02",
			ff: "\uFB00",
			ffi: "\uFB03",
			ffl: "\uFB04",
			st: "\uFB06",
			ft: "\uFB05",
			ue: "\u1D6B",
			ie: "\uAB61",
			eo: "\u1D14",
			uo: "\uAB63",
			AR: "\uD83D\uDF07",
			VB: "\uD83D\uDF6C",
			aaa: "\uD83D\uDF5B",
			AV: "\uA738\uA73A",
			av: "\uA739\uA73A",
			AY: "\uA73C",
			ay: "\uA73D",
			et: "\uD83D\uDE70",
			fi: "\uFB01",
			ib: "\u2114",
			lL: "\u1EFA",
			ll: "\u1EFB",
			ce: "\uAB62",
			Tz: "\uA728",
			tz: "\uA729",
			db: "\u0238",
			qp: "\u0239",
			th: "\u1D7A",
			ui: "\uAB50"
		};
	}

	public getUnicodeNumbers():{[key:string]:string} {
		return {
			"0": "\u24EA\u24FF\uFF10\uD835\uDFCE\uD835\uDFD8\uD835\uDFF6\uD835\uDFE2\uD835\uDFEC\u2080\u2070\uD835\uDFF6\uD835\uDE7E\uD83C\uDD00\uD83C\uDD01\u03B8\u25CB\u0BE6\u0D66\u0E50\u0ED0\u0F20\u0FC0\u101D\u1040\u1090\u3358",
			"1": "\u2460\u2776\uFF11\uD835\uDFCF\uD835\uDFD9\uD835\uDFF7\uD835\uDFE3\uD835\uDFED\u2081\xB9\u2474\u21C2\u295C\u2488\uD83C\uDD02\u24F5\u0391\u2160\u2170\u02E6\u2951\u1091\u3359\u33E0\uA747",
			"2": "\u2461\u2777\uFF12\uD835\uDFD0\uD835\uDFDA\uD835\uDFF8\uD835\uDFE4\uD835\uDFEE\u2082\xB2\u2475\u1614\uD835\uDDB2\u2489\uD83C\uDD03\u24F6\u0392\u2161\u2171\u03E8\u057B\u14BE\u14BF\u335A\u33E1\u218A",
			"3": "\u2462\u2778\uFF13\uD835\uDFD1\uD835\uDFDB\uD835\uDFF9\uD835\uDFE5\uD835\uDFEF\u2083\xB3\u2476\u0190\u13CB\u248A\uD83C\uDD04\u24F7\u0393\u2162\u2172\u0545\u10F3\u0C69\u1012\u1093\u10D5\u10D9\u335B\u33E2\u218B\uA762\uA763\uA76A\uA76B\uA7AB",
			"4": "\u2463\u2779\uFF14\uD835\uDFD2\uD835\uDFDC\uD835\uDFFA\uD835\uDFE6\uD835\uDFF0\u2084\u2074\u2477\uD835\uDDC1\u15B8\u248B\uD83C\uDD05\u24F8\u0394\u2163\u2173\u03E4\u02AE\u1094\u10B9\u13CE\u335C\u33E3",
			"5": "\u2464\u277A\uFF15\uD835\uDFD3\uD835\uDFDD\uD835\uDFFB\uD835\uDFE7\uD835\uDFF1\u2085\u2075\u2478\uD835\uDDB2\u091F\u248C\uD83C\uDD06\u24F9\u0395\u2164\u2174\u01BC\u0495\u0F4F\u1095\u335D\u33E4",
			"6": "\u2465\u277B\uFF16\uD835\uDFD4\uD835\uDFDE\uD835\uDFFC\uD835\uDFE8\uD835\uDFF2\u2086\u2076\u2479\uD835\uDFFF\u10DB\u248D\uD83C\uDD07\u24FA\u03DA\u2165\u2175\u03B4\u03ED\u1096\u10DB\u13EE\u335E\u33E5\uA77A",
			"7": "\u2466\u277C\uFF17\uD835\uDFD5\uD835\uDFDF\uD835\uDFFD\uD835\uDFE9\uD835\uDFF3\u2087\u2077\u247A\u1228\u0662\u248E\uD83C\uDD08\u24FB\u0396\u2166\u2176\uD801\uDC87\u204A\u1097\u335F\u33E6",
			"8": "\u2467\u277D\uFF18\uD835\uDFD6\uD835\uDFE0\uD835\uDFFE\uD835\uDFEA\uD835\uDFF4\u2088\u2078\u247B\uD835\uDFFE\u09EA\u248F\uD83C\uDD09\u24FC\u0397\u2167\u2177\u03D0\u10D6\u1098\u3360\u33E7",
			"9": "\u2468\u277E\uFF19\uD835\uDFD7\uD835\uDFE1\uD835\uDFFF\uD835\uDFEB\uD835\uDFF5\u2089\u2079\u247C\uD835\uDFFC\u2647\u2490\uD83C\uDD0A\u24FD\u0398\u2168\u2178\u018D\uD835\uDE90\u0B68\u0B67\u0C78\u1099\u10DC\u13ED\u3361\u33E8\uA770",
			"10": "\u2469\u277F\u247D\u2491\u24FE\u0399\u2169\u2179\u3248\u3362\u33E9",
			"11": "\u246A\u24EB\u247E\u2492\u216A\u217A\u3363\u33EA",
			"12": "\u246B\u24EC\u247F\u2493\u216B\u217B\u3364\u33EB",
			"13": "\u246C\u24ED\u2480\u2494\u3365\u33EC",
			"14": "\u246D\u24EE\u2481\u2495\u3366\u33ED",
			"15": "\u246E\u24EF\u2482\u2496\u3367\u33EE",
			"16": "\u246F\u24F0\u2483\u2497\u3368\u33EF",
			"17": "\u2470\u24F1\u2484\u2498\u3369\u33F0",
			"18": "\u2471\u24F2\u2485\u2499\u336A\u33F1",
			"19": "\u2472\u24F3\u2486\u249A\u336B\u33F2",
			"20": "\u2473\u24F4\u2487\u249B\u039A\u3249\u336C\u33F3",
			"21": "\u3251\u336D\u33F4",
			"22": "\u3252\u336E\u33F5",
			"23": "\u3253\u336F\u33F6",
			"24": "\u3254\u3370\u33F7",
			"25": "\u3255\u33F8",
			"26": "\u3256\u33F9",
			"27": "\u3257\u33FA",
			"28": "\u3258\u33FB",
			"29": "\u3259\u33FC",
			"30": "\u325A\u039B\u324A\u33FD",
			"31": "\u325B\u33FE",
			"32": "\u325C",
			"33": "\u325D",
			"34": "\u325E",
			"35": "\u325F",
			"36": "\u32B1",
			"37": "\u32B2",
			"38": "\u32B3",
			"39": "\u32B4",
			"40": "\u32B5\u039C\u324B",
			"41": "\u32B6",
			"42": "\u32B7",
			"43": "\u32B8",
			"44": "\u32B9",
			"45": "\u32BA",
			"46": "\u32BB",
			"47": "\u32BC",
			"48": "\u32BD",
			"49": "\u32BE",
			"50": "\u32BF\u039D\u324C",
			"60": "\u039E\u324D",
			"70": "\u039F\u324E",
			"80": "\u03A0\u324F",
			"90": "\u03DE",
			"100": "\u216D\u217D",
			"500": "\u216E\u217E",
			"1000": "\u216F\u217F\u2180",
			"5000": "\u2181",
			"10000": "\u2182",
			"50000": "\u2187",
			"100000": "\u2188",
			"1/4": "\xBC",
			"1/2": "\xBD",
			"3/4": "\xBE",
			"1/7": "\u2150",
			"1/9": "\u2151",
			"1/10": "\u2152",
			"1/3": "\u2153",
			"2/3": "\u2154",
			"1/5": "\u2155",
			"2/5": "\u2156",
			"3/5": "\u2157",
			"4/5": "\u2158",
			"1/6": "\u2159",
			"5/6": "\u215A",
			"1/8": "\u215B",
			"3/8": "\u215C",
			"5/8": "\u215D",
			"7/8": "\u215E",
			"1/": "\u215F",
			"0/3": "\u2189"
		};
	}

	public getUnicodeMarks():{[key:string]:string} {
		return {
			" ": "\u2423 \xA0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000",
			"'": "\u02B9\u02BB\u02BC\u02BD\u02BE\u02BF\u02CA\u02CB\u02F4\u0374\u0559\u055A\u055B\u055C\u055D\u1FBD\u1FBF\u1FFD\u1FFE\u200B\u200C\u200D\u200E\u200F\u2032\u2035\uFF40\uA78B\uA78C\u0384\uFF07\u02C8\u144A\u16CC\u2018\u2019\u201B\xB4",
			"-": "\u2010\u2011\u2012\u2013\u2014\u2015\u2043\u207B\u208B\u2212\u2500\u2501\u2CBB\uA7F7\u2796\u02D7\uFE58\u2CBA-\u2A29\u2A2A\u2A2B\u2A2C\uFF0D\u1680",
			"=": "\u02ED\u207C\u208C\uA78A\u1400\u2E40\u30A0\uA4FF\u2250\u2251\u2252\u2253\u2254\u2255\u2256\u2257\u2258\u2259\u225A\u225B\u225C\u225D\u225E\u225F\u2260\uFF1D",
			'"': "\u02BA\u02EE\u02F5\u02F6\u1FCD\u1FCE\u1FDD\u1FDE\u201C\u201D\u201E\u201F\u2033\u2034\u2036\u2037\uFF02\u3003\u1CD3\u02DD",
			"": "\u180E\u200B\uFEFF",
			"+": "\u207A\u208A\uD800\uDE9B\u2795\u16ED\u2A22\u2A23\u2A24\u2A25\u2A26\u2A27\u2A28",
			"~": "\u02F7\u1FC0\u2053\u223C\u223D\u223E\u02DC\u223B\u223F\u2E1E\u2E1F\uFF5E",
			"<": "\u02C2\u2039\u16B2\u1438\u276E\u227A\u22D6\u2770\u27E8\u276C\u29FC\u2A79\u2A7B\u2AAA",
			">": "\u02C3\u203A\u1433\u276F\u227B\u22D7\u2771\u27E9\u276D\u29FD\u2A7A\u2A7C\u2AAB",
			"\\": "\u2216\uFF3C\u2F02\u31D4\u29F5\u4E36\uFE68\u29F9\u27CD",
			"/": "\u2044\u2F03\u2CC6\u27CB\u30CE\u2571\u3033\u2215\u29F8\xF7",
			":": "\u02F8\uA789\u0589\u16EC\uFE30\u02D0\u2236\uFF1A\u205A\uA4FD",
			",": "\u2E32\u2E41\xB8\u02CC\u02CE\u02CF\u0375\u1FBE\u201A\u066B\uA4F9\u2E34\uFF0C\u3001",
			_: "\u02CD\uFF3F\uFE4D\uFE4E\uFE4F",
			"?": "\u2753\u2754\xBF\u2E2E\u097D\u2426\u2E18",
			"	": "\u21E5\u21E4\u21C6\u21B9",
			"!": "\u2757\u2755\xA1\u01C3\u2D51\uFF01",
			"(": "\u207D\u208D\u2772\u3014\u2768\uFD3E\u276A\u27EE\uFF08",
			")": "\u207E\u208E\u2773\u3015\u2769\uFD3F\u276B\u27EF\uFF09",
			"\n": "\u23CE\u21B5\u21B2",
			"^": "\uFF3E\u02C4\u02C6",
			"*": "\u204E\u2217\u0F1D\u22C6",
			"...": "\u2026",
			";": "\u2E35\u204F\u037E\u204F",
			"!!": "\u203C",
			"??": "\u2047",
			"?!": "\u2048",
			"!?": "\u2049",
			"%": "\u2052\u066A",
			"[": "\uFF3B",
			"]": "\uFF3D",
			"{": "\u2774\uD834\uDD14\u2774",
			"}": "\u2775\u2775",
			"..": "\u2025",
			".": "\u2024\uA4F8\uFF0E",
			"&": "\uA778",
			"#": "\uFF03"
		};
	}

	public getUnicodeFlags():{[key:string]:string} {
		return {
			"ad": "🇦🇩",
			"ae": "🇦🇪",
			"af": "🇦🇫",
			"ag": "🇦🇬",
			"ai": "🇦🇮",
			"al": "🇦🇱",
			"am": "🇦🇲",
			"ao": "🇦🇴",
			"aq": "🇦🇶",
			"ar": "🇦🇷",
			"as": "🇦🇸",
			"at": "🇦🇹",
			"au": "🇦🇺",
			"aw": "🇦🇼",
			"ax": "🇦🇽",
			"az": "🇦🇿",
			"ba": "🇧🇦",
			"bb": "🇧🇧",
			"bd": "🇧🇩",
			"be": "🇧🇪",
			"bf": "🇧🇫",
			"bg": "🇧🇬",
			"bh": "🇧🇭",
			"bi": "🇧🇮",
			"bj": "🇧🇯",
			"bl": "🇧🇱",
			"bm": "🇧🇲",
			"bn": "🇧🇳",
			"bo": "🇧🇴",
			"bq": "🇧🇶",
			"br": "🇧🇷",
			"bs": "🇧🇸",
			"bt": "🇧🇹",
			"bv": "🇧🇻",
			"bw": "🇧🇼",
			"by": "🇧🇾",
			"bz": "🇧🇿",
			"ca": "🇨🇦",
			"cc": "🇨🇨",
			"cd": "🇨🇩",
			"cf": "🇨🇫",
			"cg": "🇨🇬",
			"ch": "🇨🇭",
			"ci": "🇨🇮",
			"ck": "🇨🇰",
			"cl": "🇨🇱",
			"cm": "🇨🇲",
			"cn": "🇨🇳",
			"co": "🇨🇴",
			"cr": "🇨🇷",
			"cu": "🇨🇺",
			"cv": "🇨🇻",
			"cw": "🇨🇼",
			"cx": "🇨🇽",
			"cy": "🇨🇾",
			"cz": "🇨🇿",
			"de": "🇩🇪",
			"dj": "🇩🇯",
			"dk": "🇩🇰",
			"dm": "🇩🇲",
			"do": "🇩🇴",
			"dz": "🇩🇿",
			"ec": "🇪🇨",
			"ee": "🇪🇪",
			"eg": "🇪🇬",
			"eh": "🇪🇭",
			"er": "🇪🇷",
			"es": "🇪🇸",
			"et": "🇪🇹",
			"fi": "🇫🇮",
			"fj": "🇫🇯",
			"fk": "🇫🇰",
			"fm": "🇫🇲",
			"fo": "🇫🇴",
			"fr": "🇫🇷",
			"ga": "🇬🇦",
			"gb": "🇬🇧",
			"gd": "🇬🇩",
			"ge": "🇬🇪",
			"gf": "🇬🇫",
			"gg": "🇬🇬",
			"gh": "🇬🇭",
			"gi": "🇬🇮",
			"gl": "🇬🇱",
			"gm": "🇬🇲",
			"gn": "🇬🇳",
			"gp": "🇬🇵",
			"gq": "🇬🇶",
			"gr": "🇬🇷",
			"gs": "🇬🇸",
			"gt": "🇬🇹",
			"gu": "🇬🇺",
			"gw": "🇬🇼",
			"gy": "🇬🇾",
			"hk": "🇭🇰",
			"hm": "🇭🇲",
			"hn": "🇭🇳",
			"hr": "🇭🇷",
			"ht": "🇭🇹",
			"hu": "🇭🇺",
			"id": "🇮🇩",
			"ie": "🇮🇪",
			"il": "🇮🇱",
			"im": "🇮🇲",
			"in": "🇮🇳",
			"io": "🇮🇴",
			"iq": "🇮🇶",
			"ir": "🇮🇷",
			"is": "🇮🇸",
			"it": "🇮🇹",
			"je": "🇯🇪",
			"jm": "🇯🇲",
			"jo": "🇯🇴",
			"jp": "🇯🇵",
			"ke": "🇰🇪",
			"kg": "🇰🇬",
			"kh": "🇰🇭",
			"ki": "🇰🇮",
			"km": "🇰🇲",
			"kn": "🇰🇳",
			"kp": "🇰🇵",
			"kr": "🇰🇷",
			"kw": "🇰🇼",
			"ky": "🇰🇾",
			"kz": "🇰🇿",
			"la": "🇱🇦",
			"lb": "🇱🇧",
			"lc": "🇱🇨",
			"li": "🇱🇮",
			"lk": "🇱🇰",
			"lr": "🇱🇷",
			"ls": "🇱🇸",
			"lt": "🇱🇹",
			"lu": "🇱🇺",
			"lv": "🇱🇻",
			"ly": "🇱🇾",
			"ma": "🇲🇦",
			"mc": "🇲🇨",
			"md": "🇲🇩",
			"me": "🇲🇪",
			"mf": "🇲🇫",
			"mg": "🇲🇬",
			"mh": "🇲🇭",
			"mk": "🇲🇰",
			"ml": "🇲🇱",
			"mm": "🇲🇲",
			"mn": "🇲🇳",
			"mo": "🇲🇴",
			"mp": "🇲🇵",
			"mq": "🇲🇶",
			"mr": "🇲🇷",
			"ms": "🇲🇸",
			"mt": "🇲🇹",
			"mu": "🇲🇺",
			"mv": "🇲🇻",
			"mw": "🇲🇼",
			"mx": "🇲🇽",
			"my": "🇲🇾",
			"mz": "🇲🇿",
			"na": "🇳🇦",
			"nc": "🇳🇨",
			"ne": "🇳🇪",
			"nf": "🇳🇫",
			"ng": "🇳🇬",
			"ni": "🇳🇮",
			"nl": "🇳🇱",
			"no": "🇳🇴",
			"np": "🇳🇵",
			"nr": "🇳🇷",
			"nu": "🇳🇺",
			"nz": "🇳🇿",
			"om": "🇴🇲",
			"pa": "🇵🇦",
			"pe": "🇵🇪",
			"pf": "🇵🇫",
			"pg": "🇵🇬",
			"ph": "🇵🇭",
			"pk": "🇵🇰",
			"pl": "🇵🇱",
			"pm": "🇵🇲",
			"pn": "🇵🇳",
			"pr": "🇵🇷",
			"ps": "🇵🇸",
			"pt": "🇵🇹",
			"pw": "🇵🇼",
			"py": "🇵🇾",
			"qa": "🇶🇦",
			"re": "🇷🇪",
			"ro": "🇷🇴",
			"rs": "🇷🇸",
			"ru": "🇷🇺",
			"rw": "🇷🇼",
			"sa": "🇸🇦",
			"sb": "🇸🇧",
			"sc": "🇸🇨",
			"sd": "🇸🇩",
			"se": "🇸🇪",
			"sg": "🇸🇬",
			"sh": "🇸🇭",
			"si": "🇸🇮",
			"sj": "🇸🇯",
			"sk": "🇸🇰",
			"sl": "🇸🇱",
			"sm": "🇸🇲",
			"sn": "🇸🇳",
			"so": "🇸🇴",
			"sr": "🇸🇷",
			"ss": "🇸🇸",
			"st": "🇸🇹",
			"sv": "🇸🇻",
			"sx": "🇸🇽",
			"sy": "🇸🇾",
			"sz": "🇸🇿",
			"tc": "🇹🇨",
			"td": "🇹🇩",
			"tf": "🇹🇫",
			"tg": "🇹🇬",
			"th": "🇹🇭",
			"tj": "🇹🇯",
			"tk": "🇹🇰",
			"tl": "🇹🇱",
			"tm": "🇹🇲",
			"tn": "🇹🇳",
			"to": "🇹🇴",
			"tr": "🇹🇷",
			"tt": "🇹🇹",
			"tv": "🇹🇻",
			"tw": "🇹🇼",
			"tz": "🇹🇿",
			"ua": "🇺🇦",
			"ug": "🇺🇬",
			"um": "🇺🇲",
			"us": "🇺🇸",
			"uy": "🇺🇾",
			"uz": "🇺🇿",
			"va": "🇻🇦",
			"vc": "🇻🇨",
			"ve": "🇻🇪",
			"vg": "🇻🇬",
			"vi": "🇻🇮",
			"vn": "🇻🇳",
			"vu": "🇻🇺",
			"wf": "🇼🇫",
			"ws": "🇼🇸",
			"ye": "🇾🇪",
			"yt": "🇾🇹",
			"za": "🇿🇦",
			"zm": "🇿🇲",
			"zw": "🇿🇼"
		}
	}
}